<template>
  <b-overlay :show="loading">
    <div class="gameboardContainer " v-if="GetGameBoard">
      <b-img-lazy
        class=""
        v-if="GetGameBoard.game_board"
        style="width:100%;object-fit: cover; border-radius:15px"
        :src="
          `${GetGameBoard.game_board.game_board_file_path}${GetGameBoard.game_board.game_board_file_name}`
        "
        alt="img"
      />

      <div class="" v-for="team in teams" :key="team.id">
        <b-img
          class="rounded game-piece"
          v-if="team.currentPosition"
          ref="team"
          style="z-index:100"
          :src="`${team.team_icon_file_path}${team.team_icon_file_name}`"
          :style="team.currentPosition"
          alt="img"
          :id="`${team.id}`"
        />

        <span
          class="ml-2 mt-n4 text-black bg-white font-weight-bold size"
          style="position:absolute; z-index:110; padding-right:7px; padding-left:7px; border-radius:5px"
          :style="team.currentPosition"
          >{{ team.team_name }}</span
        >
        <b-tooltip
          :target="`${team.id}`"
          triggers="hover"
          class="my-tooltip"
          placement="bottomright"
        >
          <div class="text-white">
            <p class="size">{{ $t("team") }}: {{ team.team_name }}</p>
            <span class="size"
              >{{ team.team_total_points }} {{ $t("points") }}</span
            >
          </div>
        </b-tooltip>
      </div>
    </div>

    <div>
      <QuizModal
        ref="quizModal"
        :quiz_id="quiz_id"
        @close-modal="updateResolver"
      />
    </div>
    <div>
      <GoalReachedModal :is_Open="isOpen" />
    </div>
  </b-overlay>
</template>

<script>
import axios from "axios"
import QuizModal from "@/components/GameBoard/QuizModal.vue"
import GoalReachedModal from "@/components/GameBoard/GoalReachedModal.vue"

let showConfetti
export default {
  components: {
    QuizModal,
    GoalReachedModal,
  },
  data() {
    return {
      quiz_id: null,
      loading: false,
      BackgroundGraphicsImg: false,
      GetGameBoard: null,
      teams: null,
      resolver: [],
      myTeamResolver: [],
      otherTeamResolver: [],
      isQuizIdEncountered: false,
      isOpen: null,
      isPageActive: true,
    }
  },
  methods: {
    updateResolver() {
      this.isQuizIdEncountered = false
      if (this.otherTeamResolver.length) {
        this.otherTeamResolver.map(r => r())
        this.otherTeamResolver = []
      }
      if (this.myTeamResolver.length) {
        this.myTeamResolver.map(r => r())
        this.myTeamResolver = []
      }
      this.quiz_id = null
      this.resolver = []
    },

    async getGameBoard() {
      this.loading = true
      try {
        const response = await axios.get(`/gameboard`)
        if (response.status === 200) {
          this.GetGameBoard = response.data?.data ?? null
          let teams = response.data.data.teams
          if (teams && teams.length) {
            teams = teams.map(teamData => {
              return {
                ...teamData,
                currentPosition: null,
              }
            })
            this.teams = teams
          }
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },

    getXpos(team) {
      let x_coordinates = []
      team.steps.forEach(pos => {
        for (let value in pos) {
          if (value === "x_position") {
            x_coordinates.push(pos[value] + "%")
          }
        }
      })
      return x_coordinates
    },
    getYpos(team) {
      let y_coordinates = []
      team.steps.forEach(pos => {
        for (let value in pos) {
          if (value === "y_position") {
            y_coordinates.push(pos[value] + "%")
          }
        }
      })

      return y_coordinates
    },
    createPlayerSteps() {
      const promises = this.teams.map(team => {
        //  eslint-disable-next-line
        return new Promise(async r => {
          const xPos = this.getXpos(team)
          const yPos = this.getYpos(team)
          if (xPos.length === yPos.length) {
            const start = 0
            const end = xPos.length
            for (let i = start; i < end; i++) {
              const animationSecond = 1
              team.currentPosition = {
                top: yPos[i],
                left: xPos[i],
                transitionDuration: `${animationSecond}s`,
                animation:
                  i === end - 1 ? `jump ${animationSecond}s linear` : "",
                animationDelay: i === end - 1 ? `${animationSecond}s` : "",
              }

              const step = team.steps[i]
              if (step.quiz_id && team.my_team === "Y") {
                this.isQuizIdEncountered = true
                if (team.my_team === "Y") {
                  this.quiz_id = step.quiz_id
                  await new Promise(r => this.myTeamResolver.push(r))
                }
              } else {
                await new Promise(r => {
                  return setTimeout(() => r(), animationSecond * 1000)
                })
              }
              if (this.isQuizIdEncountered) {
                await new Promise(r => this.otherTeamResolver.push(r))
              }

              if (
                team.my_team === "Y" &&
                team.steps[i].step_type === "END" &&
                this.isPageActive
              ) {
                // Display confetti effect 8 Times
                showConfetti = setInterval(
                  () => this.$store.dispatch("showConfetti"),
                  animationSecond * 700
                )

                setTimeout(function() {
                  clearInterval(showConfetti)
                }, animationSecond * 6000)

                setTimeout(() => (this.isOpen = true), 7000)
              }
            }
            r()
          }
        })
      })
      return promises
    },
  },
  async mounted() {
    await this.getGameBoard()
    await Promise.all(this.createPlayerSteps())
  },
  beforeDestroy() {
    this.isPageActive = false
    clearInterval(showConfetti)
  },
}
</script>

<style scoped>
.gameboardContainer {
  width: 100%;
  max-width: 100%;
  max-height: 100%;

  position: relative;
}
.game-piece {
  position: absolute;
  width: 4.5%;
}
</style>

<style>
@keyframes jump {
  0% {
    transform: translate(0, -50%);
  }
  40% {
    transform: translate(0, -20%);
  }
  100% {
    transform: translate(0, 0);
  }
}
.size {
  font-size: 10px;
}

@media (min-width: 500px) {
  .size {
    font-size: 11px;
  }
}
@media (min-width: 800px) {
  .size {
    font-size: 12px;
  }
}
@media (min-width: 1200px) {
  .size {
    font-size: 16px;
  }
}
</style>
