<template>
  <b-row no-gutters>
    <b-col cols="12" v-show="!this.loading && !this.teamPace">
      <p class="text-muted">
      <!--  {{ $t("dashboard.inspiration") }}-->
      </p>
    </b-col>
    <b-col cols="12">
      <Canvas />
    </b-col>

    <b-col cols="12" class="mt-3" v-if="this.teamPace">
      <b-card>
        <b-overlay :show="loading">
          <div class="d-flex align-items-center pl-1">
            <div class="" style="width:fit-content">
              <b-img-lazy
                class="rounded-circle  "
                style="object-fit: cover;"
                height="65"
                width="65"
                center
                :src="`${this.teamPace.file_path}${this.teamPace.file_name}`"
                alt=""
              />
            </div>

            <div class=" ml-4 pr-3 size">
              <span class="text-terciary font-weight-normal ">
                {{ this.teamPace.team_pace_text }}</span
              >
            </div>
          </div>
        </b-overlay>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import axios from "axios"
import Canvas from "@/components/GameBoard/Canvas.vue"
export default {
  components: {
    Canvas,
  },
  data() {
    return {
      teamPace: null,
      loading: false,
    }
  },

  methods: {
    async getTeamPace() {
      this.loading = true
      try {
        const response = await axios.get(`/gameboard/teampace`)
        if (response.status === 200) {
          this.teamPace = response.data?.data ?? {}
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
  },

  created() {
    this.getTeamPace()
  },
}
</script>

<style scoped>
.size {
  font-size: 14px;
}
@media (min-width: 800px) {
  .size {
    font-size: 14px;
  }
}
@media (min-width: 1200px) {
  .size {
    font-size: 15px;
  }
}
</style>
