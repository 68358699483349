<template>
  <div v-if="meRankCard" class="card">
    <!-- Team Card -->
    <b-card v-if="meRankCard.name" class="shadow-none">
      <b-link
        style="text-decoration:none"
        :to="{ path: '/team/' + meRankCard.id }"
      >
        <div class=" d-flex align-items-center pl-1">
          <span class=" h5 text-terciary font-weight-bold mr-3">
            {{ meRankCard.rank }}.
          </span>
          <div class="  " style="width:fit-content">
            <b-img-lazy
              class="rounded-circle  "
              style="object-fit: cover;"
              height="50"
              width="50"
              center
              :src="`${meRankCard.file_path}${meRankCard.file_name}`"
              alt=""
            />
          </div>

          <div class="d-flex ml-4  text-terciary flex-grow-1 text-box">
            <div class=" d-flex flex-column">
              <span class="font-weight-bold"> {{ $t("teams.my_team") }} </span>
              <span class=" font-weight-normal size">
                {{ meRankCard.name }}
              </span>
            </div>

            <span class="size"> {{ meRankCard.team_points }} p </span>
          </div>
        </div>
      </b-link>
    </b-card>

    <!-- Individual Card -->
    <b-card v-else class="shadow-none">
      <div class=" d-flex align-items-center pl-1">
        <span class=" h5 text-terciary font-weight-bold mr-3">
          {{ meRankCard.rank }}.
        </span>
        <div class="  " style="width:fit-content">
          <b-img-lazy
            class="rounded-circle  "
            style="object-fit: cover;"
            height="50"
            v-if="meRankCard.file_path && meRankCard.file_name"
            width="50"
            center
            :src="`${meRankCard.file_path}${meRankCard.file_name}`"
            alt=""
          />
          <b-avatar v-else style="background:#999999" size="50"
            ><small>{{ meRankCard.initials }}</small></b-avatar
          >
        </div>

        <div class="d-flex ml-4  text-terciary flex-grow-1 text-box">
          <div class=" d-flex flex-column">
            <span class="font-weight-bold">
              {{ $t("dashboard.my_rank") }}
            </span>
            <span v-if="meRankCard.full_name" class=" font-weight-normal">
              {{ meRankCard.full_name }}
            </span>
            <span v-else class=" font-weight-normal">
              {{ meRankCard.initials }}
            </span>
          </div>
          <span> {{ meRankCard.my_points }} p </span>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  props: ["meRankCard"],
}
</script>

<style scoped>
.card >>> .card-body {
  padding: 0.68rem 1.2rem !important;
}
.text-box {
  flex-direction: row;
  justify-content: space-between;
}
.size {
  font-size: 12px;
}
@media (max-width: 360px) {
  .text-box {
    flex-direction: column;
  }
}
@media (min-width: 800px) {
  .size {
    font-size: 13px;
  }
}
@media (min-width: 1200px) {
  .size {
    font-size: 14px;
  }
}
</style>
