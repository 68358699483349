<template>
  <div>
    <!-- Question Modal -->

    <b-modal
      id="quiz-modal"
      centered
      hide-footer
      hide-header
      size="md"
      @hidden="closeModal"
    >
      <b-overlay :show="loading">
        <b-container fluid class="p-4" v-if="this.Question">
          <!-- Header Section -->
          <div class="d-flex align-items-center  justify-content-between">
            <h3 class="text-primary">{{ $t("dashboard.quiz") }}!</h3>
            <p
              v-if="this.Question && !this.quizResponse"
              @click="closeModal"
              style="cursor:pointer; color:#D4D4D4"
            >
              {{ $t("skip") }}
            </p>
            <p v-else @click="closeModal" style="cursor:pointer; color:#D4D4D4">
              {{ $t("close") }}
            </p>
          </div>

          <div class="mt-4">
            <b-row cols="1" align-v="center" class="">
              <b-col cols="12" class="mb-3">
                <!-- Question -->
                <p class="text-primary font-weight-bold">
                  {{ Question.question }}
                </p>
              </b-col>
            </b-row>

            <div v-if="this.Question && !this.quizResponse">
              <b-form @submit.prevent="submitQuiz">
                <b-row cols="1" align-v="center" class="">
                  <b-col cols="12">
                    <b-form-group v-slot="{ ariaDescribedby }">
                      <b-form-radio-group
                        v-model="selectedAnswer"
                        id="answer"
                        size="md"
                        :aria-describedby="ariaDescribedby"
                        stacked
                      >
                        <b-form-radio
                          v-for="answer in Question.answers"
                          :key="answer.quiz_answer_id"
                          type="radio"
                          :value="answer.quiz_answer_id"
                          class="d-flex"
                          >{{ answer.answer }}</b-form-radio
                        >
                      </b-form-radio-group>
                    </b-form-group>
                  </b-col>

                  <b-col class="mt-3">
                    <b-button
                      block
                      variant="primary"
                      type="submit"
                      :disabled="!isValid"
                      v-if="!spinnerLoading"
                    >
                      {{ $t("dashboard.submit_answer") }}
                    </b-button>
                    <b-button
                      block
                      variant="primary"
                      disabled
                      v-if="spinnerLoading"
                    >
                      <b-spinner small></b-spinner>
                      <span class="sr-only">Loading...</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </div>

            <!-- Response -->
            <div v-else>
              <div class="mt-2">
                <div v-if="quizResponse.correct_answer_flg === 'Y'">
                  <p
                    class="text-primary text-center px-5 font-weight-medium mb-2"
                  >
                    {{ $t("dashboard.correct_answer") }}
                  </p>
                  <p class="text-primary text-center px-5 ">
                    You earned {{ quizResponse.earned_points }} points.
                  </p>
                </div>
                <div v-else>
                  <p
                    class="text-primary text-center px-5 font-weight-medium mb-2"
                  >
                    {{ $t("dashboard.wrong_answer") }}
                  </p>
                  <p class="text-primary text-center px-5 mb-2">
                    {{ $t("dashboard.correct_answer_is") }}:
                    {{ quizResponse.correct_answer }}
                  </p>
                  <p class="text-primary text-center px-5 ">
                    {{ $t("dashboard.better_luck") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </b-container>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "QuizModal",
  props: ["quiz_id"],

  data() {
    return {
      loading: false,
      selectedAnswer: null,
      Question: null,
      spinnerLoading: false,
      quizResponse: null,
    }
  },

  computed: {
    isValid() {
      return this.selectedAnswer
    },
  },

  methods: {
    openModal() {
      this.$bvModal.show("quiz-modal")
    },

    closeModal() {
      this.$bvModal.hide("quiz-modal")
      this.$emit("close-modal")
    },

    // Get Quiz Question
    async getQuiz() {
      if (this.quiz_id) {
        try {
          let data = {
            quiz_id: this.quiz_id,
          }

          const response = await axios.get(`/gameboard/quizquestion`, {
            params: data,
          })

          if (response.status == 200) {
            this.Question = response.data?.data[0] ?? null
            // Open Modal
            this.$bvModal.show("quiz-modal")
          }
        } catch ({ response }) {
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: response.data.error_message,
          })
        } finally {
          this.spinnerLoading = false
        }
      }
    },

    // Submit Answer
    async submitQuiz() {
      this.spinnerLoading = true

      try {
        let data = {
          quiz_answer_id: this.selectedAnswer,
        }

        const response = await axios.post("/gameboard/quizquestion", data)
        if (response.status == 200) {
          this.quizResponse = response.data?.data ?? null
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.spinnerLoading = false
      }
    },

    // To Clear the Input  when Modal Closes
    clearInput() {
      this.selectedAnswer = null
    },
  },
  watch: {
    quiz_id() {
      this.getQuiz()
    },
  },

  mounted() {
    this.$root.$on("bv::modal::show", () => {
      this.clearInput()
      this.quizResponse = null
    })
  },
}
</script>

<style scoped>
#answer >>> .custom-control {
  font-size: 16px;
  padding: 10px 40px;
  margin-bottom: 8px;
}

#answer
  >>> .custom-radio
  .custom-control-input:checked
  ~ .custom-control-input {
  background: #b2b1cc;
  border-radius: 20px;
}
#answer
  >>> .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background: #e5399e;
  border: none;
}

div >>> #quiz-modal___BV_modal_content_ {
  border-radius: 15px;
  border: none;
}
</style>
