<template>
  <div>
    <b-modal
      id="goal-reached-modal"
      centered
      hide-footer
      hide-header
      size="md"
      @hidden="closeModal"
    >
      <b-container fluid class="p-4">
        <!-- Header Section -->
        <div class="d-flex align-items-center  justify-content-between">
          <h3 class="text-primary">{{ $t("dashboard.goal_reached") }}</h3>

          <p @click="closeModal" style="cursor:pointer; color:#D4D4D4">
            {{ $t("close") }}
          </p>
        </div>

        <div class="mt-4">
          <b-row cols="1" align-v="center" class="">
            <b-col cols="12" class="mb-3">
              <p class="text-primary font-weight-bold">
                {{ $t("dashboard.goal_reached_horray") }}
              </p>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "GoalReachedModal",
  props: ["is_Open"],

  methods: {
    openModal() {
      this.$bvModal.show("goal-reached-modal")
    },

    closeModal() {
      this.$bvModal.hide("goal-reached-modal")
    },
  },
  watch: {
    is_Open() {
      this.openModal()
    },
  },
}
</script>

<style scoped>
div >>> #goal-reached-modal___BV_modal_content_ {
  border-radius: 15px;
  border: none;
}
</style>
