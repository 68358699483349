<template>
  <b-container class="my-2 my-sm-5" fluid style="overflow:hidden">
    <b-row>
      <b-col cols="12" class="">
        <b-button style="border-radius:11px" variant="primary" to="/teams">
          <span style="font-size:14px">
            {{ $t("dashboard.add_to_gameboard") }}
          </span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Dashboard Section -->
    <div class="mt-3 mb-2">
      <!-- Animation/Image -->
      <div class="">
        <GameBoard />
      </div>

      <!-- Leaderboard Tabs -->
      <b-row class="mt-1 mt-md-4 px-3">
        <b-tabs
          content-class="mt-4"
          class="tabs-clear my-3"
          nav-class="flex-row w-100"
          style="width:100%"
          :justified="['xs', 'sm', 'md'].includes($store.state.breakpoint)"
          lazy
        >
          <!-- Team Leaderboard Tab -->
          <b-tab :title="$t('dashboard.team_leaderboard')" class=" " active>
            <b-row no-gutters v-if="orgLeaderBoardExist">
              <b-col cols="12" class="d-flex justify-content-end pr-3">
                <span class="text-terciary mr-3 font-weight-semibold">
                  {{ $t("dashboard.teams_only_my_organization") }}
                </span>
                <b-form-checkbox
                  v-model="TeamChecked"
                  name="check-button"
                  switch
                  class="checkbox-input"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>

            <b-row no-gutters class="mt-5">
              <b-col cols="12">
                <MeRankCard
                  :meRankCard="
                    TeamChecked ? TeamLBRankMyOrg : TeamLBRankALLTeam
                  "
                />
              </b-col>
            </b-row>
            <b-row no-gutters class="mt-5">
              <b-col cols="12" v-if="TeamChecked">
                <b-overlay :show="loading">
                  <div>
                    <AllRankCard
                      v-for="team in TeamLBMyOrg"
                      :key="team.rank"
                      :team="team"
                      class="mb-3"
                    />

                    <!-- Functions  trigger when user reaches bottom of the div -->
                    <div
                      v-if="TeamLBMyOrg.length"
                      v-observe-visibility="TeamLBScrolledToBottom"
                    ></div>
                  </div>
                </b-overlay>
              </b-col>
              <b-col cols="12" v-else>
                <b-overlay :show="loading">
                  <div>
                    <AllRankCard
                      v-for="team in TeamLBALLTeam"
                      :key="team.rank"
                      :team="team"
                      class="mb-3"
                    />

                    <!-- Functions  trigger when user reaches bottom of the div -->
                    <div
                      v-if="TeamLBALLTeam.length"
                      v-observe-visibility="TeamLBALLTeamScrolledToBottom"
                    ></div>
                  </div>
                </b-overlay>
              </b-col>
            </b-row>
          </b-tab>

          <!-- Individual Leaderboard Tab -->
          <b-tab :title="$t('dashboard.individual_leaderboard')">
            <b-row no-gutters v-if="orgLeaderBoardExist" class="mb-2">
              <b-col cols="12" class="d-flex justify-content-end">
                <span class="text-terciary mr-3 font-weight-semibold">
                  {{ $t("dashboard.individuals_only_my_organization") }}
                </span>
                <b-form-checkbox
                  v-model="IndividualChecked"
                  name="check-button"
                  switch
                  class="checkbox-input"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>

            <!-- LeaderBoard Full Length Card -->
            <b-row no-gutters class="mt-5">
              <b-col cols="12">
                <MeRankCard
                  :meRankCard="IndividualChecked ? MyLBRankOrg : MyLBRank"
                />
              </b-col>
            </b-row>

            <b-row no-gutters class="mt-5">
              <b-col cols="12" v-if="IndividualChecked">
                <b-overlay :show="loading">
                  <div>
                    <AllRankCard
                      v-for="team in MyLeaderBoard"
                      :key="team.rank"
                      :team="team"
                      class="mb-3"
                    />

                    <!-- Functions  trigger when user reaches bottom of the div -->
                    <div
                      v-if="MyLeaderBoard.length"
                      v-observe-visibility="ScrolledToBottom"
                    ></div>
                  </div>
                </b-overlay>
              </b-col>
              <b-col cols="12" v-else>
                <b-overlay :show="loading">
                  <div>
                    <AllRankCard
                      v-for="team in AllLeaderBoard"
                      :key="team.rank"
                      :team="team"
                      class="mb-3"
                    />

                    <!-- Functions  trigger when user reaches bottom of the div -->
                    <div
                      v-if="AllLeaderBoard.length"
                      v-observe-visibility="AllLeaderBoardScrolledToBottom"
                    ></div>
                  </div>
                </b-overlay>
              </b-col>
            </b-row>
          </b-tab>

          <!-- Organization Leaderboard Tab -->
          <b-tab
            :title="$t('dashboard.organization_leaderboard')"
            v-if="OrgLeaderBoard.length"
          >
            <b-row class="mt-4">
              <b-col cols="12">
                <b-overlay :show="loading">
                  <div>
                    <div
                      v-for="org in OrgLeaderBoard"
                      :key="org.rank"
                      class="mb-4"
                    >
                      <b-card :class="org.my_org == '1' && 'my-org-bg'">
                        <div class="d-flex align-items-center pl-1">
                          <span class="h5 text-terciary font-weight-bold mr-3">
                            {{ org.rank }}.
                          </span>

                          <div
                            class="d-flex ml-4 pr-3 text-terciary flex-grow-1 justify-content-between"
                          >
                            <span class=" font-weight-normal">
                              {{ org.name }}
                            </span>
                            <span> {{ org.points }} {{ $t("points") }} </span>
                          </div>
                        </div>
                      </b-card>
                    </div>

                    <!-- Functions  trigger when user reaches bottom of the div -->
                    <div
                      v-if="OrgLeaderBoard.length"
                      v-observe-visibility="OrgLeaderBoardScrolledToBottom"
                    ></div>
                  </div>
                </b-overlay>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import MeRankCard from "@/components/Dashboard/MeRankCard.vue"
import GameBoard from "@/components/GameBoard/GameBoard.vue"
import AllRankCard from "@/components/Dashboard/AllRankCard"
import axios from "axios"
export default {
  components: {
    MeRankCard,
    AllRankCard,
    GameBoard,
  },
  data() {
    const team_checked_storage = localStorage.getItem("team_checked")
    const individual_checked_storage = localStorage.getItem(
      "individual_checked"
    )
    return {
      loading: false,
      orgLeaderBoardExist: null,
      checked: false,
      TeamChecked: team_checked_storage
        ? team_checked_storage === "true" // string to boolean
        : true, // fallback to true
      IndividualChecked: individual_checked_storage
        ? individual_checked_storage === "true" // string to boolean
        : true, // fallback to true
      OrgLeaderBoard: [],
      OrgLeaderBoardLink: null,
      TeamLBRankMyOrg: null,
      TeamLBALLTeam: [],
      TeamLBALLTeamLink: null,
      TeamLBMyOrg: [],
      TeamLBMyOrgLink: null,
      TeamLBRankALLTeam: null,
      MyLBRankOrg: null,
      MyLBRank: null,
      MyLeaderBoard: [],
      AllLeaderBoard: [],
      AllLeaderBoardLink: null,
      MyLeaderBoardLink: null,
    }
  },
  watch: {
    TeamChecked(val) {
      localStorage.setItem("team_checked", val)
    },
    IndividualChecked(val) {
      localStorage.setItem("individual_checked", val)
    },
  },
  methods: {
    async checkOrgLeaderboardExist() {
      try {
        const response = await axios.get(`/leaderboard/exist`)
        if (response.status === 200) {
          this.orgLeaderBoardExist = response.data?.data ?? null
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      }
    },
    async getTeamLeaderboardRankMyOrg() {
      try {
        const response = await axios.get(
          `/leaderboard/my/team/rank/organization`
        )
        if (response.status === 200) {
          this.TeamLBRankMyOrg = response.data?.data[0] ?? null
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      }
    },
    async getTeamLeaderboardMyOrg(pageURL) {
      this.loading = true
      let url = `/leaderboard/my/team`
      if (pageURL) {
        url = pageURL
      }
      try {
        const response = await axios.get(url)
        if (response.status === 200) {
          this.TeamLBMyOrg = [
            ...this.TeamLBMyOrg,
            ...(response.data?.data ?? []),
          ]
          this.TeamLBMyOrgLink = response.data?.links ?? null
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
    async getTeamLeaderboardAllTeam(pageURL) {
      this.loading = true
      let url = `/leaderboard/team/all`
      if (pageURL) {
        url = pageURL
      }
      try {
        const response = await axios.get(url)
        if (response.status === 200) {
          this.TeamLBALLTeam = [
            ...this.TeamLBALLTeam,
            ...(response.data?.data ?? []),
          ]
          this.TeamLBALLTeamLink = response.data?.links ?? null
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
    async getTeamLeaderboardRankAllTeam() {
      try {
        const response = await axios.get(`/leaderboard/my/team/rank`)
        if (response.status === 200) {
          this.TeamLBRankALLTeam = response.data?.data[0] ?? null
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      }
    },
    async getMyLeaderboardRankMyOrg() {
      try {
        const response = await axios.get(`/leaderboard/my/rank/organization`)
        if (response.status === 200) {
          this.MyLBRankOrg = response.data?.data[0] ?? null
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      }
    },
    async getMyLeaderboardRank() {
      try {
        const response = await axios.get(`/leaderboard/my/rank`)
        if (response.status === 200) {
          this.MyLBRank = response.data?.data[0] ?? null
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      }
    },
    async getMyLeaderboard(pageURL) {
      this.loading = true
      let url = `/leaderboard/my`
      if (pageURL) {
        url = pageURL
      }
      try {
        const response = await axios.get(url)
        if (response.status === 200) {
          this.MyLeaderBoard = [
            ...this.MyLeaderBoard,
            ...(response.data?.data ?? []),
          ]
          this.MyLeaderBoardLink = response.data?.links ?? null
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
    async getAllLeaderboard(pageURL) {
      this.loading = true
      let url = `/leaderboard/all`
      if (pageURL) {
        url = new URL(pageURL)
        url = url.toString().substring(url.origin.length)
        url = url.replace(/^(\/[\w-]+)*\/api\//, "/") // replace possible duplicate api path
      }
      try {
        const response = await axios.get(url)
        if (response.status === 200) {
          this.AllLeaderBoard = [
            ...this.AllLeaderBoard,
            ...(response.data?.data ?? []),
          ]
          this.AllLeaderBoardLink = response.data?.links ?? null
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
    async getOrgLeaderboard(pageURL) {
      this.loading = true
      let url = `/leaderboard/organizations`
      if (pageURL) {
        url = new URL(pageURL)
        url = url.toString().substring(url.origin.length)
        url = url.replace(/^(\/[\w-]+)*\/api\//, "/") // replace possible duplicate api path
      }
      try {
        const response = await axios.get(url)
        if (response.status === 200) {
          this.OrgLeaderBoard = [
            ...this.OrgLeaderBoard,
            ...(response.data?.data ?? []),
          ]
          this.OrgLeaderBoardLink = response.data?.links ?? null
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },

    // Pagination Functions
    ScrolledToBottom(isVisible) {
      if (!isVisible) {
        return
      }
      if (this.MyLeaderBoardLink.more_pages) {
        this.getMyLeaderboard(this.MyLeaderBoardLink.next_page_url)
      }
    },
    TeamLBScrolledToBottom(isVisible) {
      if (!isVisible) {
        return
      }
      if (this.TeamLBMyOrgLink.more_pages) {
        this.getTeamLeaderboardMyOrg(this.TeamLBMyOrgLink.next_page_url)
      }
    },
    TeamLBALLTeamScrolledToBottom(isVisible) {
      if (!isVisible) {
        return
      }
      if (this.TeamLBALLTeamLink.more_pages) {
        this.getTeamLeaderboardAllTeam(this.TeamLBALLTeamLink.next_page_url)
      }
    },
    AllLeaderBoardScrolledToBottom(isVisible) {
      if (!isVisible) {
        return
      }
      if (this.AllLeaderBoardLink.more_pages) {
        this.getAllLeaderboard(this.AllLeaderBoardLink.next_page_url)
      }
    },
    OrgLeaderBoardScrolledToBottom(isVisible) {
      if (!isVisible) {
        return
      }
      if (this.OrgLeaderBoardLink.more_pages) {
        this.getOrgLeaderboard(this.OrgLeaderBoardLink.next_page_url)
      }
    },
  },

  created() {
    this.checkOrgLeaderboardExist()
    this.getTeamLeaderboardRankMyOrg()
    this.getTeamLeaderboardAllTeam()
    this.getTeamLeaderboardRankAllTeam()
    this.getTeamLeaderboardMyOrg()
    this.getMyLeaderboardRank()
    this.getMyLeaderboardRankMyOrg()
    this.getMyLeaderboard()
    this.getAllLeaderboard()
    this.getOrgLeaderboard()
  },
}
</script>

<style scoped>
.my-org-bg {
  background: #d1c4e9;
}
</style>
